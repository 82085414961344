import { useContext, useEffect, useState } from "react";
import { RedeemCoupon } from "../../service/RedeemCoupon";
// import AlertModal from "../Models/AlertModel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LoaderContext } from "../../context/LoaderContext";
import { sendMessage } from "../../service/SendMessage";
import { getStatus } from "../../service/GetStatus";
import { activateCoupon } from "../../service/ActivateCoupon";
import { SmsMessage } from "../../service/SmsMessage";
import axios from "axios";
import AddressForm from "../Forms/AddressForm";
import Address from "./Details/Physical/Adress";
import DigitalCouponRedeemInstructions from "./Details/Digital/Instruction";
import TrackingModal from "../Models/TrackingModal";
import OrderConfirmationModal from "../Models/OrderConfirmationModal";
import { toast } from "react-toastify";

export default function ProductItem({
  coupon,
  index,
  handleCoupons,
  currPage,
  isRedeemedOneCoupon
}) {
  const [pendingmodal, setPendingModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [cancelmodal, setCancelModal] = useState(false);
  const [notExistModal, setnotExistModal] = useState(false);
  const [show, setShow] = useState(false);
  const [smsModal, setSmsModal] = useState({ state: false });
  const [errorModal, setErrorModal] = useState({ state: false });
  const [copied, setCopied] = useState(false);
  const [copiedTwo, setCopiedTwo] = useState(false);
  const [resultModal, setResultModal] = useState({ state: false });
  const [whatsappModal, setWhatsappModal] = useState({ state: false });
  const [statusModal, setStatusModal] = useState(false);
  const [activationModal, setActivationModal] = useState(false);
  const [activationCoupon, setActivationCoupon] = useState(null);

  const { setLoading } = useContext(LoaderContext);

  const handleClose = () => {
    setShow(false);
  };

  const handleclose = () => {
    setPendingModal(false);
  };
  const handleCLose = () => {
    setCancelModal(false);
  };

  useEffect(() => {
    setCopied(false);
    setCopiedTwo(false);
  }, [coupon]);

  const handleResultModalClose = () => {
    setResultModal({ state: false });
    setErrorModal({ state: false });
    setnotExistModal(false);
  };

  // const handleRedeem = async (coupon) => {
  //   setLoading(true);
  //   const response = await RedeemCoupon(coupon.id, coupon.coupon_code);
  //   if (response?.data?.success) {
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //     console.log(response);
  //     setResultModal({ state: true, card: response.data.data });
  //   } else if (response.status === 201) {
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //     setErrorModal({ state: true });
  //   } else {
  //     setLoading(false);
  //   }
  //   handleCoupons();
  // };

  const handleRedeem = async (coupon) => {
    setLoading(true);
    const response = await RedeemCoupon(coupon.id, coupon.coupon_code);

    if (response?.data?.success) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      if (!response.data?.data?.data?.getData) {
        toast.error("Something went wrong");
        return false;
      }

      if (response?.data?.data?.data?.getData?.status === 1) {
        setModal(true);
      } else if (response?.data?.data?.data?.getData?.status === 4) {
        setCancelModal(true);
      } else {
        setResultModal({ state: true, card: response?.data?.data?.data });
      }
    } else if (response.status === 201) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setErrorModal({ state: true });
    } else {
      setLoading(false);
    }
    handleCoupons();
  };

  const copyText = (text, id) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    if (id === 1) {
      setCopied(true);
    } else {
      setCopiedTwo(true);
    }
  };

  const handleWhatsapp = async (id) => {
    setLoading(true);
    const response = await sendMessage(id);
    setLoading(false);
    if (response.data.success) {
      setWhatsappModal({ state: true });
    }
  };

  const handleSMS = async (id) => {
    setLoading(true);
    const response = await SmsMessage(id);
    setLoading(false);
    if (response.data.success) {
      setSmsModal({ state: true });
    }
  };

  const createEncodedText = (coupon) => {
    if (coupon.type == "physical") {
      return encodeURIComponent(
        `Dear Customer, Your order details are as below:\n\n*Product Name:* ${coupon?.title}\n*Address:* ${coupon?.address?.street?.replaceAll("\n", " ")}, ${coupon?.address?.city}, ${coupon?.address?.state}, ${coupon?.address?.zipCode}\n*Status:* Placed\n*Expected Date:* ${new Date(new Date().setDate(new Date(coupon.redeem_date).getDate() + 15)).toLocaleDateString() || "__"}\n\n*Thank You!*`
      );
    } else {
      return encodeURIComponent(
        `Dear Customer, Your voucher details are as below:\n\n*Voucher Name:* ${coupon?.title}\n*Voucher Value:* ${coupon?.price_point}\n*Gift Card ID:* ${coupon?.cardNumber}\n*Gift Card/Voucher Code:* ${coupon?.cardPin}\n*Expiry Date:* ${new Date(new Date(coupon.expireAt)).toLocaleDateString() || "__"}\n*Steps to redeem and T&C:* ${coupon?.brand_name?.toLowerCase() === "amazon" ? "https://bit.ly/almondsai" : "https://bit.ly/3pPBA28"} \n\n*Thank You!*`
      );
    }

  };

  const handleRedirect = (coupon) => {
    const encodedText = createEncodedText(coupon);
    window.location.href = `https://wa.me/?text=${encodedText}&lang=en`;
  };

  // const handleStatusCheck = async (coupon) => {
  //   setLoading(true);
  //   const response = await getStatus(coupon?.id, coupon?.coupon_code);
  //   handleCoupons();
  //   setLoading(false);
  //   if (response && response.data.success) {
  //     setStatusModal({ status: true });
  //   } else {
  //     setStatusModal({ status: false });
  //     setTimeout(() => {
  //       handleCoupons();
  //     }, 1000);
  //   }
  // };

  const [trackingModal, setTrackingModal] = useState(false);

  const handleShowTracking = () => {
    setTrackingModal(true);
  }

  const handleStatusCheck = async (coupon) => {
    setLoading(true);
    const response = await getStatus(coupon?.id, coupon?.coupon_code);
    setLoading(false);
    console.log("response", response);

    if (response && response.data.success && response.data.data.data) {
      const status = response.data.data.data.qwickcilver_status;

      if (status == "complete") {
        setStatusModal({ status: true });
      } else if (status == "pending") {
        setPendingModal(true);
      } else if (status == "canceled") {
        setCancelModal(true);
      } else {
        // Handle other statuses or conditions as needed
        setStatusModal({ status: false });
      }
    } else {
      if (!response.data.data) {
        setnotExistModal(true);
      }
    }

    setTimeout(() => {
      handleCoupons();
    }, 1000);
  };

  // const handleActivate = async (coupon) => {
  //   setLoading(true);
  //   const response = await activateCoupon(coupon?.id, coupon?.coupon_code);
  //   if (response && response?.data?.data)
  //     setActivationCoupon(response?.data?.data);
  //   handleCoupons();
  //   setLoading(false);
  //   if (response && response.data.success) {
  //     setActivationModal({ status: true });
  //   } else {
  //     setActivationModal({ status: false });
  //   }
  // };

  const handleActivate = async (coupon) => {
    setLoading(true);
    const response = await activateCoupon(coupon?.id, coupon?.coupon_code);

    if (response && response.data.data.data.getData) {
      const status = response.data.data.data.getData.status;
      setActivationCoupon(response.data.data.data);

      handleCoupons();
      setLoading(false);

      if (response.data.success && status != 4) {
        setActivationModal({ status: true });
      } else if (response.data.success && status === 4) {
        setCancelModal(true);
      } else {
        setActivationModal({ status: false });
      }
    }
  };

  const [orderConfirmationModal, setOrderConfirmationModal] = useState(false);

  useEffect(() => {
    console.log(resultModal);
  }, [resultModal]);

  const [addressModal, setAddressModal] = useState(false);
  const handleAddressRequest = () => {
    try {
      setAddressModal(true);
    } catch (err) {
      return err.message;
    }
  }

  return (
    <>
      <div className="mc-product-item">
        {/* <div className="mc-product-serial-number">{((currPage - 1) * 10) + index + 1}.</div> */}
        <div className="mc-product-image-container col-md-3">
          <div
            class="card"
            style={{
              width: "12rem",
              height: "250px",
              backgroundColor: "white",
              border: "none",
              zIndex: "",
            }}
          >
            <img
              className="mc-product-image"
              src={coupon?.image || "images/coupon.png"}
              alt=""
            />
          </div>
          <div
            className="mc-product-desc-container mc-coupon-code-container"
            style={{ marginLeft: "-13px" }}
          >
            <h3 className="mc-product-name">{coupon?.coupon_code || "__"}</h3>
            {(coupon.type === "digital") && <div className="mc-price-container">
              {/* <span className="min-price">{coupon?.currency?.symbol || ""}{coupon?.cost || "₹100"}</span> */}
              <span>Voucher Value: </span>
              <span className="max-price">
                {coupon?.currency?.symbol || ""}
                {(coupon?.price_point || "")}
              </span>
            </div>}
          </div>
        </div>

        <div className="mc-product-desc-container description">
          <p className="mc-coupon-label">Title</p>
          <h3 className="mc-coupon-title">{coupon?.title || "__"}</h3>
          <p className="mc-coupon-label">Description</p>
          <h3 className="mc-coupon-description">
            {coupon.description
              ? coupon.description.length > 250
                ? `${coupon.description.slice(0, 250)}...`
                : coupon.description
              : "__"}
          </h3>
        </div>

        <div className="mc-product-desc-container description">
          <p className="mc-coupon-label">SKU</p>
          <h3 className="mc-coupon-title">{coupon?.sku || "__"}</h3>
          <p className="mc-coupon-label">Brand Name</p>
          <h3 className="mc-coupon-description">{coupon?.brand_name || "__"}</h3>
          {coupon.expireAt && (
            <>
              <p className="mc-coupon-label">Ordered On</p>
              <h3 className="mc-coupon-description">
                {new Date(coupon.created_at).toLocaleDateString() || "__"}
              </h3>
            </>
          )}
          {coupon.expireAt && new Date(coupon.expireAt) > new Date() && (
            <>
              <p className="mc-coupon-label">{coupon.type === "physical" ? "Expires On" : "Code Expires On"}</p>
              <h3 className="mc-coupon-description">
                {new Date(new Date(coupon.expireAt)).toLocaleDateString() || "__"}
              </h3>
            </>
          )}
          {new Date(coupon.expireAt) < new Date() && (
            <>
              <p className="mc-coupon-label">{coupon.type === "physical" ? "Expires On" : "Code Expires On"}</p>
              <p className="mc-coupon-label" style={{ color: "#ea8423" }}>
                Coupon has expired
              </p>
            </>
          )}

          {coupon?.status === 2 && coupon.expireAt && (
            <>
              <p className="mc-coupon-label">{coupon.type === "physical" ? "Activated On" : "Code Activated On"}</p>
              <h3 className="mc-coupon-description">
                {new Date(coupon.redeem_date).toLocaleDateString() || "__"}
              </h3>
            </>
          )}
          {coupon?.status === 2 && (
            <p className="mc-coupon-label">
              Steps to redeem and T&C:{" "}
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                href={coupon?.brand_name?.toLowerCase() === "amazon" ? "https://bit.ly/almondsai" : "https://bit.ly/3pPBA28"}
              >
                Click here
              </a>
            </p>
          )}
        </div>
        {coupon?.status == 5 ? (
          <div
            className="mc-message-container d-flex align-items-center p-3  col-md-3"
            style={{
              marginLeft: "auto",
              borderLeft: "1px solid #ddd",
            }}
          >
            {coupon && coupon?.status == 5 && (
              <div
                className="text-wrap text-center "
              >

                The coupon code will become active within 2 to 3 business days.

              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {coupon?.status == 4 ? (
          <div
            className="mc-message-container d-flex align-items-center p-3  col-md-3"
            style={{
              marginTop: "4%",
              borderLeft: "1px solid #9da1a5",
            }}
          >
            {coupon && coupon?.status == 4 && (
              <div
                className="text-wrap text-center "
              >
                Hey! Please try to activate the digital voucher after a little
                while. 😊
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {coupon?.status === 2 && (
          <div className="mc-product-desc-container description">

            {coupon.type === "physical" && <Address coupon={coupon} />}

            {coupon.type === "digital" &&
              <>
                {coupon?.brand_name === "Amazon" ? (
                  <>
                    {coupon?.status === 2 &&
                      coupon?.cardPin &&
                      new Date(coupon.expireAt) > new Date() && (
                        <>
                          <p className="mc-coupon-label">Gift Card/Voucher Code</p>
                          <h3 className="mc-coupon-pin">
                            <span className="mc-coupon-pin-text">{coupon?.cardPin}</span>
                            <span onClick={() => copyText(coupon?.cardPin, 2)}>
                              {!copiedTwo ? (
                                <i className="fa-solid fa-copy"></i>
                              ) : (
                                <i className="fa-solid fa-check"></i>
                              )}
                            </span>
                          </h3>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {coupon?.status === 2 &&
                      coupon?.cardNumber &&
                      new Date(coupon.expireAt) > new Date() && (
                        <>
                          <p className="mc-coupon-label">Gift Card ID</p>
                          <h3 className="mc-coupon-card">
                            <span className="mc-coupon-card-text">
                              {coupon?.cardNumber || "___"}
                            </span>
                            <span onClick={() => copyText(coupon?.cardNumber, 1)}>
                              {!copied ? (
                                <i className="fa-solid fa-copy"></i>
                              ) : (
                                <i className="fa-solid fa-check"></i>
                              )}
                            </span>
                          </h3>
                        </>
                      )}
                  </>
                )}

                {coupon?.brand_name === "Amazon" ? (
                  <>
                    {coupon?.status === 2 &&
                      coupon?.cardNumber &&
                      new Date(coupon.expireAt) > new Date() && (
                        <>
                          <p className="mc-coupon-label">Gift Card ID</p>
                          <h3 className="mc-coupon-card">
                            <span className="mc-coupon-card-text">
                              {coupon?.cardNumber || "___"}
                            </span>
                            <span onClick={() => copyText(coupon?.cardNumber, 1)}>
                              {!copied ? (
                                <i className="fa-solid fa-copy"></i>
                              ) : (
                                <i className="fa-solid fa-check"></i>
                              )}
                            </span>
                          </h3>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {coupon?.status === 2 &&
                      coupon?.cardPin &&
                      new Date(coupon.expireAt) > new Date() && (
                        <>
                          <p className="mc-coupon-label">Gift Card/Voucher Code</p>
                          <h3 className="mc-coupon-pin">
                            <span className="mc-coupon-pin-text">{coupon?.cardPin}</span>
                            <span onClick={() => copyText(coupon?.cardPin, 2)}>
                              {!copiedTwo ? (
                                <i className="fa-solid fa-copy"></i>
                              ) : (
                                <i className="fa-solid fa-check"></i>
                              )}
                            </span>
                          </h3>
                        </>
                      )}
                  </>
                )}


                {coupon?.status === 2 && coupon?.activationUrl && (
                  <>
                    <p className="mc-coupon-label">Activation Url</p>
                    <h3 className="mc-coupon-pin">
                      <span className="mc-coupon-card-text">
                        {coupon?.activationUrl || "__"}
                      </span>
                      <span
                        onClick={() => window.open(coupon?.activationUrl, "_blank")}
                      >
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </span>
                    </h3>
                  </>
                )}
                {coupon?.status === 2 && coupon?.activationCode && (
                  <>
                    <p className="mc-coupon-label">Activation Code</p>
                    <h3 className="mc-coupon-pin">
                      <span className="mc-coupon-pin-text">
                        {coupon?.activationCode || "__"}
                      </span>
                      <span onClick={() => copyText(coupon?.activationCode, 2)}>
                        {!copiedTwo ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-solid fa-check"></i>
                        )}
                      </span>
                    </h3>
                  </>
                )}
              </>
            }
          </div>
        )}



        {(isRedeemedOneCoupon && coupon.option == 1 && coupon.status == 0 && coupon.isStock == 0) ? <>
          <div
            className="mc-message-container side-descriptor d-flex align-items-center p-3  col-md-3"
            style={{
              marginLeft: "auto",
              borderLeft: "1px solid #ddd",
            }}
          >

            <div
              className="text-wrap text-left side-description"
            >

              This voucher is claimed against another product

            </div>
          </div>
        </> : (isRedeemedOneCoupon && coupon.status != 2 && coupon.isStock == 1) ? <>
          <div
            className="mc-message-container side-descriptor d-flex align-items-center p-3  col-md-3"
            style={{
              marginLeft: "auto",
              borderLeft: "1px solid #ddd",
            }}
          >
            <div
              className="text-wrap text-left side-description"
            >
              Out of Stock
            </div>
          </div></> :
          <>
            <div className="mc-coupon-container" style={{ marginLeft: 0 }}>
              {coupon &&
                coupon?.status == 0 &&
                new Date(coupon.expireAt) > new Date() && (
                  <button
                    className=" mc-coupon-redeem-button"
                    onClick={() => {
                      if (coupon.type === "physical") {
                        handleAddressRequest(coupon);
                      } else {
                        setShow(true);
                      }
                    }}
                  >
                    {coupon.type === "physical" ? "Order Now" : "Activate Now"}
                  </button>
                )}
              {coupon &&
                coupon?.status === 2 &&
                new Date(coupon.expireAt) > new Date() && (
                  <div className="mc-btns-wrapper">
                    {coupon.type === "physical" && <button onClick={handleShowTracking} className="mc-coupon-redeem-button track-order">
                      Track Order
                    </button>}
                    <br />
                    <button className="mc-coupon-redeem-button redeemed">
                      {coupon.type === "physical" ? "Ordered" : "Activated"}
                    </button>
                    <br />
                    <div style={{ display: "flex", gap: "10px" }}>
                      <button
                        className="mc-coupon-redeem-button whatsapp-button"
                        onClick={() => handleRedirect(coupon)}
                      >
                        <i className="fa-brands fa-whatsapp"></i>
                      </button>
                      {/* <button
                  className="mc-coupon-redeem-button sms-button"
                  onClick={() => {
                    handleSMS(coupon?.id); // Add the SMS click handler
                  }}
                >
                  <i className="fas fa-sms"></i>
                </button> */}
                    </div>
                  </div>
                )}

              {coupon && coupon?.status == 1 && (
                <div>
                  <button
                    className="mc-btn-status"
                    onClick={() => {
                      handleStatusCheck(coupon);
                    }}
                  >
                    Check Status
                  </button>
                </div>
              )}
              {coupon && coupon?.status === 3 && !coupon?.cardNumber && (
                <div>
                  <button
                    className="mc-btn-status"
                    onClick={() => {
                      handleActivate(coupon);
                    }}
                  >
                    Activate
                  </button>
                </div>
              )}
            </div>
          </>
        }

        {pendingmodal && (
          <Modal show={pendingmodal} onHide={handleclose} style={{ top: "35%" }}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              Please Try again After Sometime
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ padding: "8px 18px" }}
                variant="secondary"
                onClick={handleclose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {cancelmodal && (
          <Modal show={cancelmodal} onHide={handleCLose} style={{ top: "35%" }}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                textAlign: "center",
                justifyContent: "center",
                fontWeight: 600,
                fontSize: "17px",
              }}
            >
              Hey! Please try to activate the digital voucher after a little
              while. 😊
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ padding: "8px 18px" }}
                variant="primary"
                onClick={handleCLose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {show && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "22px" }}>
                Please Confirm to activate this coupon!
              </Modal.Title>
            </Modal.Header>
            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer>
              <Button
                style={{ padding: "8px 18px" }}
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn btn-outline-warning"
                // className="confirm-button"
                // variant="danger"
                onClick={() => {
                  handleRedeem(coupon);
                  handleClose();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {resultModal.state && (
          <Modal
            show={resultModal.state}
            onHide={handleResultModalClose}
            centered
          >
            <img className="mc-check-gif" src="images/check.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">
                Congratulations! Your Coupon is now Activated
              </h3>
              <p>Please find the details below.</p>
              <div className="mc-product-desc-container description">
                {resultModal?.card?.getData?.cardNumber !== null && (
                  <div>
                    <p className="mc-coupon-label">Gift Card ID</p>
                    <h3 className="mc-coupon-card">
                      <span className="mc-coupon-card-text">
                        {resultModal?.card?.getData?.cardNumber}
                      </span>
                      <span
                        onClick={() =>
                          copyText(resultModal?.card?.getData?.cardNumber, 1)
                        }
                      >
                        {!copied ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-solid fa-check"></i>
                        )}
                      </span>
                    </h3>
                  </div>
                )}
                {resultModal?.card?.getData?.cardPin !== null && (
                  <div>
                    <p className="mc-coupon-label">Gift Card/Voucher Code</p>
                    <h3 className="mc-coupon-pin">
                      <span className="mc-coupon-pin-text">
                        {resultModal?.card?.getData?.cardPin}
                      </span>
                      <span
                        onClick={() =>
                          copyText(resultModal?.card?.getData?.cardPin, 2)
                        }
                      >
                        {!copiedTwo ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-solid fa-check"></i>
                        )}
                      </span>
                    </h3>
                  </div>
                )}
                {resultModal?.card?.getData?.activationUrl && (
                  <>
                    <p className="mc-coupon-label">Activation Url</p>
                    <h3 className="mc-coupon-card">
                      <span className="mc-coupon-card-text">
                        {resultModal?.card?.getData?.activationUrl}
                      </span>
                      <span
                        onClick={() =>
                          window.open(
                            resultModal?.card?.getData?.activationUrl,
                            "_blank"
                          )
                        }
                      >
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </span>
                    </h3>
                  </>
                )}
                {resultModal?.card?.getData?.activationCode && (
                  <>
                    <p className="mc-coupon-label">Activation Code</p>
                    <h3 className="mc-coupon-card">
                      <span className="mc-coupon-card-text">
                        {resultModal?.card?.getData?.activationCode}
                      </span>
                      <span
                        onClick={() =>
                          copyText(resultModal?.card?.getData?.activationCode, 1)
                        }
                      >
                        {!copied ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-solid fa-check"></i>
                        )}
                      </span>
                    </h3>
                  </>
                )}
              </div>
            </div>
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button redeemed-button"
                variant="success"
                onClick={handleResultModalClose}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Error modal */}
        {errorModal.state && (
          <Modal show={errorModal.state} onHide={handleResultModalClose} centered>
            <img className="mc-check-gif" src="images/cross.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">Oh! Sorry !</h3>
              <p>Something went wrong, please try again after sometimes.</p>
              <div className="mc-product-desc-container description"></div>
            </div>

            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button redeemed-button"
                variant="danger"
                onClick={handleResultModalClose}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {notExistModal && (
          <Modal show={notExistModal} onHide={handleResultModalClose} centered>
            <img className="mc-check-gif" src="images/cross.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">Oh! Sorry !</h3>
              <p>This Coupon does not exist</p>
              <div className="mc-product-desc-container description"></div>
            </div>

            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button redeemed-button"
                variant="danger"
                onClick={handleResultModalClose}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {whatsappModal.state && (
          <Modal
            show={whatsappModal.state}
            onHide={handleResultModalClose}
            centered
          >
            <img className="mc-check-gif" src="images/check.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">Message Sent !</h3>
              {/* <p>Please find your coupon creds.</p> */}
              {/* <div className="mc-product-desc-container description">
                    <h3 className="mc-coupon-card"><span className="mc-coupon-card-text">{coupon?.cardNumber || "Null"}</span><span onClick={() => copyText(coupon?.cardNumber, 1)}>{!copied ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                    <h3 className="mc-coupon-pin"><span className="mc-coupon-pin-text">{coupon?.cardPin || "Null"}</span><span onClick={() => copyText(coupon?.cardPin, 2)}>{!copiedTwo ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                </div> */}
            </div>

            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button redeemed-button"
                variant="success"
                onClick={() => {
                  setWhatsappModal({ state: false });
                }}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <Modal centered show={modal}>
          <Modal.Body>
            <div>
              <img
                className="mc-pending-image"
                // src="/images/pending.jpg"
                alt=""
              />
              <p className="mc-coupon-status-text">
                Please hold on🤚! While your Digital Voucher code is being
                generated.{" "}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                setModal(false);
              }}
              className="mc-btn-status"
              style={{ margin: "0px 0px 0px auto" }}
            >
              Next
            </button>
          </Modal.Footer>
        </Modal>

        {smsModal.state && (
          <Modal show={smsModal.state} onHide={handleResultModalClose} centered>
            <img className="mc-check-gif" src="images/check.gif" alt="" />
            <div className="mc-redeemed-coupon-details">
              <h3 className="mc-coupon-redeemed-text">Whoohoo! Message Sent !</h3>
              {/* <p>Please find your coupon creds.</p> */}
              {/* <div className="mc-product-desc-container description">
                    <h3 className="mc-coupon-card"><span className="mc-coupon-card-text">{coupon?.cardNumber || "Null"}</span><span onClick={() => copyText(coupon?.cardNumber, 1)}>{!copied ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                    <h3 className="mc-coupon-pin"><span className="mc-coupon-pin-text">{coupon?.cardPin || "Null"}</span><span onClick={() => copyText(coupon?.cardPin, 2)}>{!copiedTwo ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                </div> */}
            </div>

            {/* <Modal.Body></Modal.Body> */}
            <Modal.Footer className="mc-modal-footer">
              <Button
                className="mc-coupon-redeem-button redeemed-button"
                variant="success"
                onClick={() => {
                  setSmsModal({ state: false });
                }}
              >
                Ok, got it!
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <Modal centered show={statusModal}>
          <Modal.Body>
            {statusModal.status && (
              <div>
                {/* <img
                className="mc-pending-image"
                // src="/images/pending.jpg"
                alt=""
              /> */}
                <p className="mc-coupon-status-text">
                  Activate your Digital Voucher now.
                </p>
              </div>
            )}
            {!statusModal.status && (
              <div>
                {/* <img
                className="mc-pending-image"
                src="/images/error.jpg"
                alt=""
              /> */}
                <p className="mc-coupon-status-text">
                  Error while checking status.
                  <br />
                  We will send you a notification when it is ready.
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            onClick={() => {
              setStatusModal(false);
              handleCoupons();
            }}
          >
            <button
              className="mc-btn-status"
              style={{ margin: "0px 0px 0px auto" }}
            >
              Next
            </button>
          </Modal.Footer>
        </Modal>
        <Modal centered show={activationModal}>
          {activationModal?.status && (
            <Modal.Body>
              <div className="mc-container-activation">
                <img className="mc-check-gif" src="images/check.gif" alt="" />
                <div className="mc-redeemed-coupon-details">
                  <h3 className="mc-coupon-redeemed-text">
                    Whoohoo! Coupon Redeemed !
                  </h3>
                  <p>Please find your coupon creds.</p>
                  <div className="mc-product-desc-container description">
                    {activationCoupon &&
                      activationCoupon?.getData?.cardNumber && (
                        <div>
                          <p className="mc-coupon-label">Gift Card ID</p>
                          <h3 className="mc-coupon-card">
                            <span className="mc-coupon-card-text">
                              {activationCoupon?.getData?.cardNumber}
                            </span>
                            <span
                              onClick={() =>
                                copyText(activationCoupon?.getData?.cardNumber, 1)
                              }
                            >
                              {!copied ? (
                                <i className="fa-solid fa-copy"></i>
                              ) : (
                                <i className="fa-solid fa-check"></i>
                              )}
                            </span>
                          </h3>
                        </div>
                      )}
                    {activationCoupon && activationCoupon?.getData?.cardPin && (
                      <div>
                        <p className="mc-coupon-label">Gift Card/Voucher Code</p>
                        <h3 className="mc-coupon-pin">
                          <span className="mc-coupon-pin-text">
                            {activationCoupon?.getData?.cardPin}
                          </span>
                          <span
                            onClick={() =>
                              copyText(activationCoupon?.getData?.cardPin, 2)
                            }
                          >
                            {!copiedTwo ? (
                              <i className="fa-solid fa-copy"></i>
                            ) : (
                              <i className="fa-solid fa-check"></i>
                            )}
                          </span>
                        </h3>
                      </div>
                    )}
                    {activationCoupon &&
                      activationCoupon?.getData?.activationUrl && (
                        <>
                          {activationCoupon?.getData?.activationUrl && (
                            <p className="mc-coupon-label">Activation Url</p>
                          )}
                          <h3 className="mc-coupon-card">
                            <span className="mc-coupon-card-text">
                              {activationCoupon?.getData?.activationUrl}
                            </span>
                            <span
                              onClick={() =>
                                window.open(
                                  activationCoupon?.getData?.activationUrl,
                                  "_blank"
                                )
                              }
                            >
                              <i class="fa-solid fa-arrow-up-right-from-square"></i>
                            </span>
                          </h3>
                        </>
                      )}
                    {activationCoupon?.activationCode && (
                      <>
                        {activationCoupon?.getData?.activationCode && (
                          <p className="mc-coupon-label">Activation Code</p>
                        )}
                        <h3 className="mc-coupon-card">
                          <span className="mc-coupon-card-text">
                            {activationCoupon?.getData?.activationCode}
                          </span>
                          <span
                            onClick={() =>
                              copyText(
                                activationCoupon?.getData?.activationCode,
                                1
                              )
                            }
                          >
                            {!copied ? (
                              <i className="fa-solid fa-copy"></i>
                            ) : (
                              <i className="fa-solid fa-check"></i>
                            )}
                          </span>
                        </h3>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer
            onClick={() => {
              setActivationModal(false);
            }}
          >
            <button
              className="mc-btn-status"
              style={{ margin: "10px 10px 10px auto" }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>

      {
        addressModal && <div className={`mc-address-modal open`}>

          <div className="modal-content">

            <AddressForm
              couponData={coupon}
              onSuccess={(res) => {
                setAddressModal(false);
                handleCoupons();
                setOrderConfirmationModal(true);
              }}
              onCancel={() => {
                setAddressModal(false);
              }}
            />


          </div>

        </div>}


      <TrackingModal coupon={coupon} trackingModal={trackingModal} setTrackingModal={setTrackingModal} />
      <OrderConfirmationModal coupon={coupon} orderConfirmationModal={orderConfirmationModal} setOrderConfirmationModal={setOrderConfirmationModal} />
    </>
  );
}
